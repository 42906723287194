define('new-salsa-kl/routes/deejays', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "Deejays",
    model: function model() {
      return this.get('store').findAll('deejay');
    }
  });
});