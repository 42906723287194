define("new-salsa-kl/routes/application", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    title: function title(tokens) {
      return "Salsa KL - " + tokens;
    }
  });
});