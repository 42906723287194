define('new-salsa-kl/router', ['exports', 'new-salsa-kl/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('about');
    this.route('suggest');
    this.route('socials');
    this.route('studios', function () {
      this.route('show', { path: '/:studio_id' });
    });
    this.route('deejays', function () {
      this.route('show', { path: '/:deejay_id' });
    });
  });

  Router.reopen({
    notifyGoogleAnalytics: Ember.on('didTransition', function () {
      return ga('send', 'pageview', {
        'page': this.get('url'),
        'title': this.get('url')
      });
    })
  });

  exports.default = Router;
});