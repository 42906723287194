define('new-salsa-kl/routes/socials', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "Socials",
    model: function model() {
      return this.get('store').findAll('social').then(function (results) {
        return results.filter(function (social) {
          return social.get('status') === 'active';
        });
      });
    }
  });
});