define('new-salsa-kl/components/dj-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sortDefinition: ['name'],
    sortedDeejays: Ember.computed.sort('deejays', 'sortDefinition')
  });
});