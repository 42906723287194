define('new-salsa-kl/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'mirage/config.js should pass ESLint\n\n664:37 - \'request\' is defined but never used. (no-unused-vars)\n672:37 - \'request\' is defined but never used. (no-unused-vars)\n680:37 - \'request\' is defined but never used. (no-unused-vars)');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
});