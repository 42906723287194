define('new-salsa-kl/components/social-overview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    filterBySunday: function () {
      return this.get('socials').filterBy('day', 'Sunday');
    }.property('content.@each'),

    filterByMonday: function () {
      return this.get('socials').filterBy('day', 'Monday');
    }.property('content.@each'),

    filterByTuesday: function () {
      return this.get('socials').filterBy('day', 'Tuesday');
    }.property('content.@each'),

    filterByWednesday: function () {
      return this.get('socials').filterBy('day', 'Wednesday');
    }.property('content.@each'),

    filterByThursday: function () {
      return this.get('socials').filterBy('day', 'Thursday');
    }.property('content.@each'),

    filterByFriday: function () {
      return this.get('socials').filterBy('day', 'Friday');
    }.property('content.@each'),

    filterBySaturday: function () {
      return this.get('socials').filterBy('day', 'Saturday');
    }.property('content.@each')
  });
});